.container {
    margin: 12px auto;
    max-width: 1024px;
    text-align: center;
    padding: 0 12px;
}

.toolbar {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
}

.highlight-text {
    font-weight: 500;
    color: blue;
    display: inline-block;
    padding: 0px 4px 0px 4px;
}

.tool-item {
    margin-left: 4px;
}

.well {
    background-color: #efefef;
    border: solid 1px #c0c0c0;
    padding: 6px;
}

.ant-spin-text {
    color: white;
}
